import React from 'react'
import Page from '../components/Page'

const LegalizationPage = () => (
  <Page title="LEGLIZATION">
    <p className="mt-8 w-2/3 text-center">
      For our customers we obtain legalization so that our certified translations are legalized.<br/>
      <br/>
      We obtain the Hague Apostille or similar legalization types - if the related country is still to be a member of the Hague Convention for Abolishing the Requirement of Legalization for Foreign Public Documents.<br/>
    </p>
  </Page>
)

export default LegalizationPage